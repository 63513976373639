import React from "react"
import * as typeformEmbed from '@typeform/embed'

class Typeform extends React.Component {
  constructor(props) {
    super(props);
    this.el = null;
  }
  componentDidMount() {
    const {typeform} = this.props.widget
    if (this.el) {
      typeformEmbed.makeWidget(this.el, typeform, {
        hideFooter: true,
        hideHeaders: true,
        opacity: 0
      });
    }
  }
  render() {
    return (
      <section style={{backgroundSize: "100%"}} className="uk-section uk-section-small uk-background-muted" >
        <div className="uk-container uk-container-xsmall uk-padding-remove">
          {this.props.widget.title && (
            <h3 className="uk-text-center">{this.props.widget.title}</h3>
          )}
          <div className="uk-card uk-card-default"><div ref={(el) => this.el = el} style={{width: '100%', height: '400px'}} /></div>
        </div>
      </section>
    )
  }
}

export default Typeform;

import React from "react"
import Countdown from "./countdown"
import HeroSlideshow from "./hero-slideshow"
import Explorer from "./explorer"
import Sponsor from "./sponsor"
import ContentBlock from "./content-block"
import HeroTitleBlock from "./hero-title-block"
import DiscoverMore from "./discover-more"
import Accordion from "./accordion"
import FullContactForm from "./full-contact-form"
import Typeform from "./typeform"
export default ({widgets}) => {
  return widgets.map((widget, index) => {
    switch (widget && widget.__typename) {
      case "DatoCmsWidgetContentBlock":
        return <ContentBlock key={index} widget={widget} />
      case "DatoCmsWidgetCountdown":
        return <Countdown key={index} widget={widget} />
      case "DatoCmsWidgetDiscoverMore":
        return <DiscoverMore key={index} widget={widget} />
      case "DatoCmsWidgetExplorer":
        return <Explorer key={index} widget={widget} />
      case "DatoCmsWidgetHeroBlockWSlideshow":
        return <HeroSlideshow key={index} widget={widget} />
      case "DatoCmsWidgetHeroTitleBlock":
        return <HeroTitleBlock key={index} widget={widget} />
      case "DatoCmsWidgetSponsor":
        return <Sponsor key={index} widget={widget} />
      case "DatoCmsWidgetDetailList":
        return <Accordion key={index} widget={widget} />
      case "DatoCmsWidgetFullContactForm":
        return <FullContactForm key={index} widget={widget} />
      case "DatoCmsWidgetTypeform":
        return <Typeform key={index} widget={widget} />
      default:
        return <p key={index}>Sorry, {widget.__typename} is not a widget yet</p>
    }
  })
}

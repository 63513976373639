import React from "react"
import TruncatedHtml from './truncated-html'
export default class ContentBlock extends React.Component {

  _renderHTML = () => {
    const {widget: {body}, widget} = this.props
    return (
      <>
        {widget.truncate ? (
          <TruncatedHtml body={body} />
        ) : (
          <div dangerouslySetInnerHTML={{__html: body }}></div>
        )
        }
      </>
    )
  }

  render() {
    const {widget: {contained, containerSize, removeTopPadding, removeBottomPadding, heroImage, reversed}} = this.props
    return (
      <>
      {contained ? (
        <section className={`uk-section ${removeTopPadding ? "uk-padding-remove-top" : ""} ${removeBottomPadding ? "uk-padding-remove-bottom" : ''}`}>
          <div className={`uk-container uk-container-${containerSize}`}>
            <div uk-grid="" className={`uk-flex ${reversed ? "uk-flex-row-reverse" : ""}`}>
              {heroImage ? (
                <>
                  <div className="uk-width-1-1 uk-width-1-2@s">
                    <img alt={heroImage.alt}  data-src={`${heroImage.url}?fit=crop&w=750&h=490`} height="490" className="uk-animation-fade" width="750" uk-img="" />
                  </div>
                  <div className="uk-width-1-1 uk-width-1-2@s">
                    {this._renderHTML()}
                  </div>
                </>
              ) : (
                <div className="uk-width-1-1">
                  {this._renderHTML()}
                </div>
              )}
            </div>
          </div>
        </section>
      ) : this._renderHTML()}
      </>
    )
  }

}

import React from "react"
import SliderNavigation from './slider-navigation'
import NavigationNode from './navigation-node'
export default ({widget: {image, slideshowItems, displayTitle}}) => (
  <section className="uk-background-top-center uk-background-cover uk-background-norepeat" data-src={`${image && image.url}?w=2200`} data-sizes={image && image.fluid.sizes} uk-img="">
    <div className="uk-container uk-padding-remove">
      {
        displayTitle && (
          <h1 style={{marginTop: "30px", marginBottom: "30px"}} className="uk-light uk-text-center uk-text-uppercase">{displayTitle}</h1>
        )
      }

      <div className="uk-padding-small@m margin-small-medium uk-background-default">
        <div uk-slider="">
         <div className="uk-position-relative">
           <div className="uk-slider-container">
             <ul className="uk-slider-items uk-grid uk-child-width-1-1">
               {slideshowItems.map((slide, i) => (
                 <li key={i} className="uk-width-1-1">
                   <div uk-grid="" className="uk-grid-large uk-flex uk-flex-middle uk-grid uk-grid-collapse">
                     <div className="uk-width-1-1 uk-width-3-5@m">
                       <img alt={slide.title} data-src={slide.image && `${slide.image.url}?fit=crop&w=1000&h=650`} width="1000" className="uk-animation-fade" height="650" uk-img="target: !.uk-slider-items" />
                     </div>
                     <div className="uk-text-center uk-text-left@m uk-padding uk-width-1-1 uk-width-medium@m uk-width-large@l">
                        {slide.title && (
                         <h2 className="uk-text-secondary">{slide.title}</h2>
                        )}
                        {slide.caption && (
                          <div className="uk-text-bold text-saddle" dangerouslySetInnerHTML={{__html: slide.caption}}></div>
                        )
                        }
                        {(slide.page || slide.buttonExternalLink) && (
                          <NavigationNode locatable={slide.page} node={{externalLink: slide.buttonExternalLink}} className="uk-button uk-button-default">{slide.buttonLabel || "Learn more"}</NavigationNode>
                        )}
                      </div>
                   </div>
                 </li>
               )) }
             </ul>
           </div>
           <SliderNavigation small mobile/>
         </div>
        </div>
      </div>
    </div>
  </section>
)

import React from "react"
import NavigationNode from './navigation-node'
const countdown = {days: "DAYS", hours: "HOURS", minutes: "MIN", seconds: "SEC"}
export default ({widget: {gradient, caption, page, buttonLabel, countdownTo}}) => (
  <section className={`uk-section uk-section-small ${gradient ? "background-gradient-blue" : ""}`}>
    <div className="uk-container uk-container-small">
      <div uk-grid="" className="uk-child-width-1-1 uk-child-width-1-2@s uk-flex uk-flex-middle">
        <div className="uk-text-center">
          <h5 className={`uk-margin-small-bottom ${gradient ? "uk-light" : ""}`}>{caption}</h5>
          {page && (
            <NavigationNode locatable={page} className="uk-button uk-button-primary">{buttonLabel || "Learn more"}</NavigationNode>
          )}
        </div>
        <div>
          <div uk-countdown={`date: ${countdownTo}`} className="uk-flex uk-flex-center uk-flex-left@m">
            { Object.keys(countdown).map((k) => (
              <div key={k} className="uk-text-center uk-background-muted uk-flex uk-flex-middle uk-flex-center uk-flex-column countdown-block">
                <div className={`uk-text-secondary uk-countdown-number uk-countdown-${k}`}></div>
                <div className="uk-countdown-label">{countdown[k]}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  </section>
)
